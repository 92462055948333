import { Card, Drawer, List, Typography } from "antd"

import { Purify } from "@shared/components"
import React from "react"
import { isEmpty } from "lodash"
import moment from "moment"
import { smsEncodedUtil } from "@shared/utils"
import { useSmsDetail } from "@shared/services"

interface IFProps {
	onClose: any
	open: boolean
	smsId: string
}
export const SmsDetails: React.FC<IFProps> = ({ smsId, open, onClose }) => {
	const smsDetailsQuery = useSmsDetail({ id: smsId })

	return (
		<Drawer
			title="SMS Details"
			placement="right"
			width="400px"
			closable={true}
			onClose={onClose}
			visible={open}>
			<Card className="no-shadow">
				<Purify
					loading={smsDetailsQuery?.isLoading}
					empty={isEmpty(smsDetailsQuery?.data?.data?.data)}>
					<div className="job-search">
						<div className="flex justify-content-betweenalign-middle pl-0 pr-0">
							<div className="fancy-date">
								<span>
									{moment(
										smsDetailsQuery?.data?.data?.data?.scheduledAt
									).format("DD ")}
								</span>
								{moment(smsDetailsQuery?.data?.data?.data?.scheduledAt).format(
									"MMMM YYYY, h:mm:ss a"
								)}
							</div>
						</div>
						<h6 className="f-w-bold text-xl txt-primary pt-20">
							Recipient: {smsDetailsQuery?.data?.data?.data?.recipient}
						</h6>
						<h6 className="f-w-400 f-14 pt-20">Body:</h6>
						<p
							style={{
								backgroundColor: "rgb(227 237 248 / 28%)",
								padding: 10,
								margin: 0,
								marginBottom: 30,
								borderRadius: 4,
							}}>
							{smsEncodedUtil(smsDetailsQuery?.data?.data?.data?.body)}
						</p>
						<List
							itemLayout="vertical"
							bordered={false}
							dataSource={[
								{
									title: "Masking",
									data: smsDetailsQuery?.data?.data?.mask?.mask,
								},
								{
									title: "Sms Type",
									data: smsDetailsQuery?.data?.data?.type,
								},
								{
									title: "Count",
									data: smsDetailsQuery?.data?.data?.count,
								},
								{
									title: "Rate",
									data: smsDetailsQuery?.data?.data?.Rate,
								},
								{
									title: "Cost Rate",
									data: smsDetailsQuery?.data?.data?.costRate,
								},
								{
									title: "Cost",
									data: smsDetailsQuery?.data?.data?.cost,
								},
								{
									title: "Status",
									data: smsDetailsQuery?.data?.data?.deliveryStatus,
								},
							]}
							renderItem={(data) => (
								<List.Item
									style={{ display: "flex", justifyContent: "space-between" }}>
									<Typography.Text strong>{data.title}</Typography.Text>
									<Typography.Text>{data.data}</Typography.Text>
								</List.Item>
							)}
						/>
					</div>
				</Purify>
			</Card>
		</Drawer>
	)
}
