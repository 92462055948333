import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

import { Paths } from "@shared/constant"

const SmsTemplatesPage = lazy(() => import("./SmsTemplatePage"))

export const TemplatesRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.SmsTemplatesList} /> },
	{
		path: "sms",
		children: [
			{
				path: "",
				element: <Navigate to={Paths.SmsTemplatesList} />,
			},
			{
				path: "list",
				element: <SmsTemplatesPage />,
			},
		],
	},
]
