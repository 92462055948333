import {
	AndromedaAxiosInstance,
	CoreAxiosInstanceV2,
	QueryConfig,
} from "@shared/config"
import {
	IReportFilter,
	IReportFilterAndromeda,
} from "../interfaces/reports.interface"

import { concatQuery } from "./../utils/util-function"
import { useQuery } from "react-query"

const END_POINT = "/reports/"

export const ReportServiceV2 = {
	NAME: "ReportService",
	reportGenerate(options: ReportGenerate): any {
		return CoreAxiosInstanceV2.get(
			`${END_POINT}reportGenerate?${concatQuery(options)}`
		)
	},
	reports(options: IReportFilter): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}?${concatQuery(options)}`)
	},
}

//Andromeda

const AMENDPOINT = "/report/"

export const ReportAndromedaServiceV2 = {
	NAME: "ReportAndromedaService",
	genCsv(option: IReportFilterAndromeda) {
		return AndromedaAxiosInstance.get(`${AMENDPOINT}genCsv?	
		startDate=${option.startDate || ""}
		&endDate=${option.endDate || ""}
		&user=${option.user || ""}
		&mask=${option.mask || ""}
		&recipient=${option.recipient || ""}
		&ip=${option.ip || ""}
		&operator=${option.operator || ""}
		&smsStatus=${option.smsStatus || ""}
		&campaign=${option.campaign || ""}
		&gateway=${option.gateway || ""}`)
	},
}

interface ReportGenerate {
	config?: QueryConfig<typeof ReportAndromedaServiceV2.genCsv>
	options?: IReportFilterAndromeda
}

export const useReportGenerate = ({ config, options }: ReportGenerate = {}) => {
	const queryName = `${ReportAndromedaServiceV2.NAME}${ReportAndromedaServiceV2.genCsv.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ReportAndromedaServiceV2.genCsv(options),
	})
}

interface Reports {
	config?: QueryConfig<typeof ReportServiceV2.reports>
	options?: IReportFilter
}

export const useReports = ({ config, options }: Reports = {}) => {
	const queryName = `${ReportServiceV2.NAME}${ReportServiceV2.reports.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => ReportServiceV2.reports(options),
	})
}
