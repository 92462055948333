import { Navigate, RouteObject } from "react-router"
import React, { lazy } from "react"

import { Paths } from "@shared/constant"

const InstantMobileSmsPage = lazy(() => import("./InstantMobileSmsPage"))

export const InstantSmsRoutes: RouteObject[] = [
	{ path: "", element: <Navigate to={Paths.InstantMessage} /> },
	{
		path: "sms",
		children: [
			{
				path: ":recipient",
				element: <InstantMobileSmsPage />,
			},
		],
	},
]
