import React, { lazy } from "react"

import { Navigate } from "react-router"
import { Paths } from "@shared/constant"

const DashboardPage = lazy(() => import("./DashboardPage"))

export const DashboardRoutes = [
	{ path: "", element: <Navigate to={Paths.DefaultDashboard} /> },
	{ path: "default", element: <DashboardPage /> },
]
