import React, { CSSProperties } from "react"

import Lottie from "react-lottie"

interface IFProps {
	data?: any
	style?: CSSProperties
	config?: {
		loop?: boolean
		autoplay?: boolean
	}
}
export const LottiePlayer: React.FC<IFProps> = ({
	data,
	style,
	config = { loop: false, autoplay: true },
}) => {
	const defaultOptions = {
		...config,
		animationData: data,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	}

	return (
		<div style={style}>
			<Lottie options={defaultOptions} />
		</div>
	)
}
