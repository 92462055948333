import React, { lazy } from "react"

import { Navigate } from "react-router"
import { Paths } from "@shared/constant"

const CampaignOverviewPage = lazy(() => import("./CampaignOverviewPage"))
const CreateSmsCampaignPage = lazy(() => import("./CreateSmsCampaignPage"))
const SmsCampaignDetailPage = lazy(() => import("./SmsCampaignDetailPage"))

export const CampaignRoutes = [
	{ path: "", element: <Navigate to={Paths.CampaignOverview} /> },
	{ path: "overview", element: <CampaignOverviewPage /> },
	{
		path: "sms",
		children: [
			{ path: "", element: <Navigate to={Paths.CreateSmsCampaign} /> },
			{ path: "create", element: <CreateSmsCampaignPage /> },
			{ path: ":id", element: <SmsCampaignDetailPage /> },
		],
	},
]
