import { ContactTypes } from "."
import { Dispatch } from "redux"

export const setContactFilterUserTouchAction = (id: string) => (
	dispatch: Dispatch
) => {
	dispatch({
		type: ContactTypes.SET_CONTACT_FILTER_USER_TOUCH,
		payload: id,
	})
}
export const setContactFilterGroupTouchAction = (id: string) => (
	dispatch: Dispatch
) => {
	dispatch({
		type: ContactTypes.SET_CONTACT_FILTER_GROUP_TOUCH,
		payload: id,
	})
}
