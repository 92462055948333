interface ISMSCount {
	encoding: any
	length: number
	messages: number
	perMessage: number
	remaining: number
}

const gsm7bitChars =
	"@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà"

const gsm7bitExChar = "\\^{}\\\\\\[~\\]|€"

const gsm7bitRegExp = RegExp("^[" + gsm7bitChars + "]*$")

const gsm7bitExRegExp = RegExp("^[" + gsm7bitChars + gsm7bitExChar + "]*$")

const gsm7bitExOnlyRegExp = RegExp("^[\\" + gsm7bitExChar + "]*$")

const GSM_7BIT = "GSM_7BIT"

const GSM_7BIT_EX = "GSM_7BIT_EX"

const UTF16 = "UTF16"

const messageLength = {
	GSM_7BIT: 160,
	GSM_7BIT_EX: 160,
	UTF16: 70,
}

const multiMessageLength = {
	GSM_7BIT: 153,
	GSM_7BIT_EX: 153,
	UTF16: 67,
}

const detectEncoding = (text: any) => {
	switch (false) {
		case text.match(gsm7bitRegExp) == null:
			return GSM_7BIT
		case text.match(gsm7bitExRegExp) == null:
			return GSM_7BIT_EX
		default:
			return UTF16
	}
}

const countGsm7bitEx = (text: any) => {
	let char2
	const chars = function () {
		let _i, _len
		const _results = []
		for (_i = 0, _len = text.length; _i < _len; _i++) {
			char2 = text[_i]
			if (char2.match(gsm7bitExOnlyRegExp) != null) {
				_results.push(char2)
			}
		}
		return _results
	}.call(this)
	return chars.length
}

export const SMSCount = (text: any): ISMSCount => {
	const encoding = detectEncoding(text)
	let length = text.length

	if (encoding === GSM_7BIT_EX) {
		length += countGsm7bitEx(text)
	}

	let per_message = messageLength[encoding]
	if (length > per_message) {
		per_message = multiMessageLength[encoding]
	}
	const messages = Math.ceil(length / per_message)
	let remaining = per_message * messages - length

	if (remaining === 0 && messages === 0) {
		remaining = per_message
	}
	return {
		encoding: encoding,
		length: length,
		perMessage: per_message,
		remaining: remaining,
		messages: messages,
	}
}
