import { CoreAxiosInstanceV2, QueryConfig } from "@shared/config"

import { IDashboardFilterSms } from "@shared/interfaces"
import { concatQuery } from "@shared/utils"
import { useQuery } from "react-query"

const END_POINT = "/dashboards/"

export const DashboardServiceV2 = {
	NAME: "DashboardService",
	getSms(options: IDashboardFilterSms) {
		return CoreAxiosInstanceV2.get(`${END_POINT}getSms?
		${concatQuery(options)}`)
	},
	getSmsDashboardOverview(options: IDashboardFilterSms) {
		return CoreAxiosInstanceV2.get(`${END_POINT}getSmsDashboardOverview?
		${concatQuery(options)}`)
	},
	getSmsGroupByOperator(options: IDashboardFilterSms): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}getSmsGroupByOperator?
		${concatQuery(options)}`)
	},
	getSmsCountUsingStatus(options: IDashboardFilterSms): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}getSmsCountUsingStatus?
		${concatQuery(options)}`)
	},
	timeSeries(options: IDashboardFilterSms): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}timeSeries?
		${concatQuery(options)}`)
	},
	getLiveLogs(options: IDashboardFilterSms): any {
		return CoreAxiosInstanceV2.get(`${END_POINT}getLiveLogs?
		${concatQuery(options)}`)
	},
}
interface GetLiveLogs {
	config?: QueryConfig<typeof DashboardServiceV2.getLiveLogs>
	options?: IDashboardFilterSms
}

export const useGetLiveLogs = ({ config, options }: GetLiveLogs = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.getLiveLogs.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.getLiveLogs(options),
	})
}
interface GetSms {
	config?: QueryConfig<typeof DashboardServiceV2.getSms>
	options?: IDashboardFilterSms
}

export const useGetSms = ({ config, options }: GetSms = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.getSms.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.getSms(options),
	})
}

interface SmsCountUsingStatus {
	config?: QueryConfig<typeof DashboardServiceV2.getSmsCountUsingStatus>
	options?: IDashboardFilterSms
}

export const useSmsCountUsingStatus = ({
	config,
	options,
}: SmsCountUsingStatus = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.getSmsCountUsingStatus.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.getSmsCountUsingStatus(options),
	})
}
interface SmsDashboardOverview {
	config?: QueryConfig<typeof DashboardServiceV2.getSmsDashboardOverview>
	options?: IDashboardFilterSms
}

export const useSmsDashboardOverview = ({
	config,
	options,
}: SmsDashboardOverview = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.getSmsDashboardOverview.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.getSmsDashboardOverview(options),
	})
}

interface SmsGroupByOperator {
	config?: QueryConfig<typeof DashboardServiceV2.getSmsGroupByOperator>
	options?: IDashboardFilterSms
}

export const useSmsGroupByOperator = ({
	config,
	options,
}: SmsGroupByOperator = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.getSmsGroupByOperator.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.getSmsGroupByOperator(options),
	})
}
interface TimeSeries {
	config?: QueryConfig<typeof DashboardServiceV2.timeSeries>
	options?: IDashboardFilterSms
}

export const useTimeSeries = ({ config, options }: TimeSeries = {}) => {
	const queryName = `${DashboardServiceV2.NAME}${DashboardServiceV2.timeSeries.name}`
	return useQuery({
		...config,
		queryKey: [queryName, Object.values(options)],
		queryFn: () => DashboardServiceV2.timeSeries(options),
	})
}
