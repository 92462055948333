import React, { useState } from "react"

import Countdown from "react-countdown"

interface IFProps {
	endTime: any
	isDisappears?: boolean
	className?: string
	onComplete?: () => void
}

export const CountDownCircle: React.FC<IFProps> = ({
	className,
	isDisappears = false,
	endTime,
	onComplete,
}) => {
	const [isDone, setIsDone] = useState<boolean>(false)
	const onDone = () => {
		setIsDone(true)
		if (onComplete) {
			onComplete()
		}
	}
	return isDone && isDisappears ? (
		<div></div>
	) : (
		<div>
			<div className={`countdown-two countdown ${className ? className : ""}`}>
				<Countdown
					onComplete={onDone}
					date={new Date(endTime)}
					renderer={RendererTwo}
				/>
			</div>
		</div>
	)
}

interface IRenderProps {
	days: number | string
	hours: number | string
	minutes: number | string
	seconds: number | string
}
const RendererTwo: React.FC<IRenderProps> = ({
	days,
	hours,
	minutes,
	seconds,
}) => {
	return (
		<>
			<div>
				<ul>
					<li>
						<span id="days" className="time digits">
							{days}
						</span>
						<span className="title">{"days"}</span>
					</li>
					<li>
						<span className="time digits" id="hours">
							{hours}
						</span>
						<span className="title">{"Hours"}</span>
					</li>
					<li>
						<span className="time digits" id="minutes">
							{minutes}
						</span>
						<span className="title">{"Minutes"}</span>
					</li>
					<li>
						<span className="time digits" id="seconds">
							{seconds}
						</span>
						<span className="title">{"Seconds"}</span>
					</li>
				</ul>
			</div>
		</>
	)
}
