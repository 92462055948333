import { InstantSmsTypes } from "."

const INITIAL_STATE = {
	create: {
		user: {},
		mask: {},
		recipient: "",
		body: "",
	},
}
export const instantSmsReducer = (state = INITIAL_STATE, action: any) => {
	const { payload } = action

	switch (action.type) {
		case InstantSmsTypes.INSTANT_CLEAN_SMS_REDUCER:
			return {
				...state,
				create: {
					user: {},
					mask: {},
					recipient: "",
					body: "",
				},
			}
		//! CREATE
		case InstantSmsTypes.INSTANT_SMS_INSERT_USER:
			return {
				...state,
				create: {
					...state.create,
					user: payload,
				},
			}
		case InstantSmsTypes.INSTANT_SMS_INSERT_SEND_FORM:
			return {
				...state,
				create: {
					...state.create,
					mask: payload,
				},
			}
		case InstantSmsTypes.INSTANT_SMS_INSERT_RECIPIENT:
			return {
				...state,
				create: {
					...state.create,
					recipient: payload,
				},
			}
		case InstantSmsTypes.INSTANT_SMS_INSERT_BODY:
			return {
				...state,
				create: {
					...state.create,
					body: payload,
				},
			}

		default:
			return state
	}
}
