import { InstantSmsTypes } from "."

export const cleanInstantSmsReducerAction = () => async (dispatch: any) => {
	dispatch({
		type: InstantSmsTypes.INSTANT_CLEAN_SMS_REDUCER,
	})
}
export const instantSmsInsertUserAction = (user: any) => (dispatch: any) => {
	dispatch({
		type: InstantSmsTypes.INSTANT_SMS_INSERT_USER,
		payload: user,
	})
}

export const instantSmsInsertMaskAction = (recipient: string) => (
	dispatch: any
) => {
	dispatch({
		type: InstantSmsTypes.INSTANT_SMS_INSERT_SEND_FORM,
		payload: recipient,
	})
}
export const instantSmsInsertRecipientAction = (payload: any) => (
	dispatch: any
) => {
	dispatch({
		type: InstantSmsTypes.INSTANT_SMS_INSERT_RECIPIENT,
		payload,
	})
}

export const instantSmsInsertBodyAction = (payload: any) => (dispatch: any) => {
	dispatch({
		type: InstantSmsTypes.INSTANT_SMS_INSERT_BODY,
		payload,
	})
}
