import { createSelector } from "reselect"

const selectState = (state: any) => state.instantSms

export const selectSmsCreate = createSelector([selectState], (edge: any) => ({
	user: edge?.create?.user,
	mask: edge?.create?.mask,
	recipient: edge?.create?.recipient,
	body: edge?.create?.body,
}))
