import React, { useState } from "react"

import AsyncSelect from "react-select/lib/Async"
import { useContactGroups } from "@shared/services"

interface IFProps {
	onChange?: any
	defaultValue?: any
	isMulti?: boolean
	isDisabled?: boolean
	userId?: string
}
export const DebounceSegmentSelectField: React.FC<IFProps> = ({
	onChange,
	isMulti,
	userId,
	defaultValue,
	isDisabled = false,
}) => {
	const [options, setOptions] = useState({
		page: 1,
		take: 20,
		searchTerm: "",
	})

	const contactGroupQuery = useContactGroups({
		options: { ...options, user: userId },
	})

	const defaultOptions = contactGroupQuery?.data?.data?.data?.map(
		(segment: any) => ({
			label: segment?.name,
			value: segment,
		})
	)

	const filterFacts = (inputValue: string) => {
		setOptions({ ...options, searchTerm: inputValue })
		const normalizeUser = contactGroupQuery?.data?.data?.data?.map(
			(segment: any) => ({
				label: segment?.name,
				value: segment,
			})
		)
		return normalizeUser
	}
	const loadOptions = (inputValue: any, callback: any) => {
		setTimeout(() => {
			callback(filterFacts(inputValue))
		}, 1000)
	}
	//! End Select send form user area
	return (
		<>
			<label style={{ marginBottom: 0 }}>Segments</label>
			<AsyncSelect
				className="async-select-field"
				isLoading={contactGroupQuery.isLoading}
				loadOptions={loadOptions}
				cacheOptions={false}
				clearValue={true}
				defaultOptions={defaultOptions}
				isMulti={isMulti}
				onChange={onChange}
				isDisabled={isDisabled}
				// value={value}
				isClearable={true}
				defaultValue={isDisabled}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					border: "none",
					boxShadow: "none",
					colors: {
						...theme.colors,
					},
				})}
			/>
		</>
	)
}
