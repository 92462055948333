import { ContactTypes } from "."
import { IContactState } from "@shared/interfaces"

const INITIAL_STATE: IContactState = {
	user: "",
	groups: "",
}

export const contactReducer = (
	state = INITIAL_STATE,
	action: any
): IContactState => {
	const { payload } = action
	switch (action.type) {
		case ContactTypes.SET_CONTACT_FILTER_USER_TOUCH:
			return {
				...state,
				user: payload,
			}
		case ContactTypes.SET_CONTACT_FILTER_GROUP_TOUCH:
			return {
				...state,
				groups: payload,
			}
		default:
			return state
	}
}
