import { Empty, Spin } from "antd"
import React, { CSSProperties } from "react"

interface Props {
	loading?: boolean
	empty?: boolean
	hide?: "*" | "loader" | "empty"
	children?: any
	style?: CSSProperties
}
export const Purify: React.FC<Props> = ({
	empty = false,
	loading = false,
	hide,
	children,
	style,
}) => {
	return loading === true ? (
		hide === "loader" || hide === "*" ? (
			<span></span>
		) : (
			<Spin style={style} />
		)
	) : empty === true ? (
		hide === "empty" || hide === "*" ? (
			<span></span>
		) : (
			<Empty style={style} />
		)
	) : (
		children
	)
}
