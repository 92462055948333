import {
	CoreAxiosInstanceV2,
	MutationConfig,
	QueryConfig,
	queryClient,
} from "@shared/config"
import {
	IContactGroupsUpdate,
	ICreateContactGroup,
	IFilterContactGroups,
} from "@shared/interfaces"
import { useMutation, useQuery } from "react-query"

import { concatQuery } from "@shared/utils"

const END_POINT = "/contactGroups/"

export const ContactGroupServiceV2 = {
	NAME: "ContactGroupService",
	filter(options: IFilterContactGroups) {
		return CoreAxiosInstanceV2.get(`${END_POINT}filter?${concatQuery(options)}`)
	},
	create(payload: ICreateContactGroup) {
		return CoreAxiosInstanceV2.post(`${END_POINT}`, payload)
	},
	delete(id: string): any {
		return CoreAxiosInstanceV2.delete(`${END_POINT}${id}`)
	},
	update(payload: IContactGroupsUpdate): any {
		return CoreAxiosInstanceV2.put(`${END_POINT}${payload.id}`, {
			name: payload.name,
			status: payload.status,
		})
	},
}
interface ContactGroups {
	config?: QueryConfig<typeof ContactGroupServiceV2.filter>
	options?: IFilterContactGroups
}
export const useContactGroups = ({ config, options }: ContactGroups = {}) => {
	const queryName = `${ContactGroupServiceV2.NAME}${ContactGroupServiceV2.filter.name}`
	return useQuery({
		...config,
		queryKey: [queryName, options],
		queryFn: () => ContactGroupServiceV2.filter(options),
	})
}
interface CreateContactGroup {
	config?: MutationConfig<typeof ContactGroupServiceV2.create>
}
export const useCreateContactGroup = ({ config }: CreateContactGroup) => {
	return useMutation({
		...config,
		mutationFn: ContactGroupServiceV2.create,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactGroupServiceV2.filter.name}`
			)
		},
	})
}

interface UpdateContactGroup {
	config?: MutationConfig<typeof ContactGroupServiceV2.update>
}
export const useUpdateContactGroup = ({ config }: UpdateContactGroup) => {
	return useMutation({
		...config,
		mutationFn: ContactGroupServiceV2.update,
		onSettled: () => {
			queryClient.invalidateQueries(
				`${ContactGroupServiceV2.NAME}${ContactGroupServiceV2.filter.name}`
			)
		},
	})
}
