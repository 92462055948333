import { createSelector } from "reselect"

export const appLayout = (state: any) => state.appLayout

export const selectAppSidebarToggle = createSelector(
	[appLayout],
	(state: any) => ({
		isOpenLeftSidebar: state.isOpenLeftSidebar,
		isOpenRightSidebar: state.isOpenRightSidebar,
	})
)
